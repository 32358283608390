import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'
import PageHelmet from '../components/pageHelmet'
import BusinessDirectory from '../components/businessDirectory'

import forBizHeroImg from '../images/hero-consumers.jpg'
import forBizSvg from '../images/for-community.svg'
import SponsorsSection from '../components/sponsorsSection'

const BusinessDirectoryPage = ({ data }) => {
  let businesses = data.allGoogleSheetWebsiteDataSourceRow.nodes;
  return (
    <Layout>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
          integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
          crossorigin=""
        />
      </Helmet>
      <PageHelmet
        title="Business Directory"
        description="We are building a searchable online business directory so you can search for 'Covid-Conscious' businesses."
        image={forBizHeroImg}
      />
      <SecondaryPageHero
        heroImg={forBizHeroImg}
        iconImg={forBizSvg}
        iconImgAlt={'Industrial Building'}
        title={'BUSINESS'}
        accentedTitle={'Directory'}
        subtitle={
          'Consumers are supporting businesses that improve their community’s wellness'
        }
      />

      <div><BusinessDirectory businesses={businesses} /></div>
      <div><SponsorsSection /></div>

    </Layout>
  );
};

export default BusinessDirectoryPage

export const query = graphql`
  query BusinessDirectoryQuery {
    allGoogleSheetWebsiteDataSourceRow {
      nodes {
        businessname
        businessphysicaladdress
        businesstype
        website
        phonenumber
        city
        state
        zip
        country
        cc
        latitude
        longitude
        masksrequired
        servicesavailable
        explain
      }
    }
  }
`
