import React from 'react'
import { Map, TileLayer } from 'react-leaflet'
import PropTypes from 'prop-types'

import Spiderfy from  './spiderfy'
import BusinessMarker from  './businessDirectory/businessMarker'
import markerIcon2x from  '../../node_modules/leaflet/dist/images/marker-icon-2x.png'
import markerIcon from  '../../node_modules/leaflet/dist/images/marker-icon.png'
import markerIconShadow from  '../../node_modules/leaflet/dist/images/marker-shadow.png'

const center = [37.996, -95.845]

const sortBusinessByName = (a,b) => {
  const aVal = a.businessname.toLowerCase();
  const bVal = b.businessname.toLowerCase();
  if (aVal < bVal) { return -1; }
  if (aVal > bVal) { return 1; }
  return 0;
}
const filterBusinesses = ({ businesses, searchFilter, businessTypeFilter}) => {
  let newBusinesses = businesses;
  if (searchFilter !== '') {
    newBusinesses = newBusinesses.filter(x => x.searchText.includes(searchFilter));
  }
  if (businessTypeFilter !== 'Select A Category') {
    newBusinesses = newBusinesses.filter(x => x.businesstype === businessTypeFilter);
  }
  return newBusinesses
};

const reducer = (state, action) => {
  let filteredBusinesses, pageCount, start, paginatedBusinesses, pageNumber;
  switch (action.type) {
    case 'setZoomLevel':
      return { ...state, zoomLevel: action.payload }
    case 'setPageNumber':
      filteredBusinesses = filterBusinesses({ ...state });
      pageNumber = parseInt(action.payload)
      start = state.pageSize * pageNumber;
      paginatedBusinesses = filteredBusinesses.slice(start, Math.min(start + state.pageSize, filteredBusinesses.length))
      return {
        ...state,
        pristineView: false,
        pageNumber,
        filteredBusinesses,
        paginatedBusinesses,
      }
    case 'setSearchTextFilter':
      filteredBusinesses = filterBusinesses({ ...state, searchFilter: action.payload });
      pageCount = Math.max(Math.ceil(filteredBusinesses.length / state.pageSize));
      start = state.pageSize * state.pageNumber;
      paginatedBusinesses = filteredBusinesses.slice(0, Math.min(start + state.pageSize, filteredBusinesses.length))
      return {
        ...state,
        pristineView: false,
        searchFilter: action.payload,
        pageNumber: 0,
        pageCount,
        filteredBusinesses,
        paginatedBusinesses
      }
    case 'setBusinessTypeFilter':
      filteredBusinesses = filterBusinesses({ ...state, businessTypeFilter: action.payload });
      pageCount = Math.max(Math.ceil(filteredBusinesses.length / state.pageSize));
      start = state.pageSize * state.pageNumber;
      paginatedBusinesses = filteredBusinesses.slice(0, Math.min(start + state.pageSize, filteredBusinesses.length))
      return {
        ...state,
        pristineView: false,
        businessTypeFilter: action.payload,
        pageNumber: 0,
        pageCount,
        filteredBusinesses,
        paginatedBusinesses
      };
    default:
      throw new Error();
  }
}
const mapboxPublicToken = 'pk.eyJ1IjoicmV2aXZlZGVzaWduc3R1ZGlvcyIsImEiOiJja2I5emNzd3owajVsMnlscTZjYnozcm04In0.clvrVQ2b3tR0itUQuJAyfA';

const BusinessDirectory = ({ businesses }) => {
  businesses = businesses.sort(sortBusinessByName);
  businesses = businesses.map(x => ({...x, searchText: `${x.city} ${x.state} ${x.zip}`}))
  const initialPageSize = 10;
  const initialZoomLevel = 6;
  const [state, dispatch] = React.useReducer(reducer, {
    businesses,
    filteredBusinesses: [].concat(businesses),
    paginatedBusinesses: [].concat(businesses).slice(0, Math.min(0 + initialPageSize, businesses.length)),
    pageSize: initialPageSize,
    pageNumber: 0,
    pageCount: Math.ceil(businesses.length / initialPageSize),
    businessTypeFilter: 'Select A Category',
    searchFilter: '',
    pristineView: true,
    zoomLevel: initialZoomLevel,
  });

  const { pageNumber, filteredBusinesses, paginatedBusinesses, pristineView, pageCount, zoomLevel } = state;
  const businessesToShowOnMap = pristineView ? filteredBusinesses : paginatedBusinesses;

  const uniqueUsedTypes = businesses.map(x => x.businesstype)
    .filter((bizType, index, self) => (
      self.indexOf(bizType) === index && bizType !== null
    ))
    .sort();

  // set marker icon
  React.useEffect(() => {
    const L = require("leaflet");
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: markerIcon2x,
      iconUrl: markerIcon,
      shadowUrl: markerIconShadow,
    });
  }, []);

  if (typeof window === 'undefined') { return null; }

  const sliceStart = Math.max(pageNumber - 5, 0);
  const sliceEnd = Math.min(pageNumber + 5, pageCount);
  return (
    <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2 has-text-centered">
              We're publishing our list 'Covid-Conscious' businesses while we are building a full-featured, searchable directory containing more info about what businesses are doing. We update this list once per day.
              Businesses can <a href="/get-started">self-certify</a> to get listed. To update your profile information, <a href="/contact">contact us</a>.
            </div>
          </div>
        </div>
        <br />
        <div className="container map-filter-container">
          <div className="columns">
            <div className="column is-4 is-offset-4">
              <div className="field is-horizontal">
                <div className="field-label is-normal sr-only">
                  <label className="label">Business Type</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select style={{ display: 'inline-block', }}
                          className="input"
                          value={state.businessTypeFilter}
                          onChange={(x) => dispatch({type: 'setBusinessTypeFilter', payload: x.target.value}) } >
                          <option value={undefined}>Select A Category</option>
                          {uniqueUsedTypes.map(x => {
                            return <option key={x} value={x}>{x}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <div className="field">
                    <div className="field-label is-normal sr-only">
                      <label className="label">City or State</label>
                    </div>

                    <p className="control is-expanded has-icons-left">
                      <input className="input"
                        type="text"
                        onKeyPress={(evt) => {
                          if (evt.charCode == 13) {
                            dispatch({type: 'setSearchTextFilter', payload: evt.target.value});
                          }
                        }}
                        placeholder="City, State or Zip" />
                      <span className="icon is-small is-left">
                        <i className="fas fa-user"></i>
                      </span>
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

      <br />
      <Map center={center} zoom={zoomLevel} onZoomEnd={(evt) => dispatch({type: 'setZoomLevel', payload: evt.target._zoom })}>
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/revivedesignstudios/ckblhwfdm0gd91inyuqcnmwfc/tiles/512/{z}/{x}/{y}@2x?access_token=${mapboxPublicToken}`}
          attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
        />
        {zoomLevel >= 12 ?
            <Spiderfy
              onClick={(marker) => console.log(marker)}
              onSpiderfy={(markers) => console.log(markers)}
              onUnspiderfy={(markers) => console.log(markers)}
            >
            {businessesToShowOnMap.filter(x => x.latitude !== null).map((biz, idx) => (
              <BusinessMarker key={idx} biz={biz} />
            ))}
            </Spiderfy>
            :
            businessesToShowOnMap.filter(x => x.latitude !== null).map((biz, idx) => (
              <BusinessMarker key={idx} biz={biz} />
            ))
        }
      </Map>
        <div className="container">
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <button className="button"
              onClick={() => dispatch({type: 'setPageNumber', payload: Math.max(pageNumber - 1, 0) })}>
              Previous
            </button>
            <div style={{ textAlign: 'center' }}>
              <p style={{ marginBottom: '0' }}>{`${pageNumber+1} of ${pageCount}`}</p>
              {sliceStart > 0 && <span onClick={() => dispatch({type: 'setPageNumber', payload: 0 })}>1 ... </span>}
              {Array.from(Array(pageCount).keys())
                .slice(sliceStart, sliceEnd)
                .map((number, idx) => (
                  <React.Fragment key={number}>
                    <span>{idx === 0 ? '' : ', '}</span>
                    <span
                    style={{ cursor: 'pointer', }}
                      className={number === pageNumber ? `active` : ``}
                      data-goto-page={number}
                      onClick={() => dispatch({type: 'setPageNumber', payload: number })}>{number+1}</span>
                  </React.Fragment>
              ))}
              {sliceEnd < pageCount && <span onClick={() => dispatch({type: 'setPageNumber', payload: pageCount-1 })}> ... {pageCount}</span>}
              <p style={{ paddingTop: '10px' }}><strong>{businesses.length} Businesses. Updated Feb 2021.</strong></p>
            </div>
            <button className="button"
              onClick={() => dispatch({type: 'setPageNumber', payload: Math.min(pageNumber + 1, pageCount-1) })}>
              Next
            </button>
          </div>
        </div>
        <div className="container">
          <div className="columns">
            <div className="column is-12 center-block">
              <div className="table-container">
                <table className="table is-fullwidth">
                  <tbody>
                  <tr>
                    <th className="sticky-column"><h6>Business</h6></th>
                    {/* <th><h5>Address</th></h5> */}
                    <th><h6>City</h6></th>
                    <th><h6>State</h6></th>
                    <th><h6>'Covid-Conscious' +</h6></th>
                    <th><h6>Phone</h6></th>
                    <th><h6>Type</h6></th>
                    <th><h6>Website</h6></th>
                  </tr>
                  {paginatedBusinesses.map((biz, idx) => (
                    <tr key={idx}>
                      <td className="sticky-column table-biz">{biz.businessname}</td>
                      {/* <td>{biz.businessphysicaladdress}</td> */}
                      <td>{biz.city}</td>
                      <td>{biz.state}</td>
                      <td>{biz.masksrequired ? 'Yes! Patrons required to wear masks' : 'Unknown'}</td>
                      <td><a href={`tel:+1${biz.phonenumber}`} target="_blank">{biz.phonenumber}</a></td>
                      <td>{biz.businesstype}</td>
                      <td><a href={biz.website} target="_blank">{biz.website}</a></td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

BusinessDirectory.propTypes = {
  businesses: PropTypes.array.isRequired
}

export default BusinessDirectory
